
import Vue from 'vue'
import { favicons, images, metadata } from '~/assets/ts/utils/metadata'
import SiteStructure from '~/components/site/Structure.vue'

export default Vue.extend({
  components: { SiteStructure },
  head(this: Record<string, any>) {
    return metadata(
      {
        images: images(require('@/assets/images/ogImage.png')),
        description: this.$t('sa.subtitle').toString(),
        favicons: favicons('sa'),
      },
      this
    )
  },
  mounted() {
    // TODO: Remove this in January 2025
    if (document.cookie.includes('sa._token.local')) {
      console.log('Found sa._token.local')
      // Delete old authentication cookies we no longer use
      const oldCookieNames = [
        'sa._token.local',
        'sa._token_expiration.local',
        'sa._refresh_token.local',
        'sa._refresh_token_expiration.local',
        'sa.strategy',
      ]
      oldCookieNames.forEach((cookieName) => {
        // delete the cookie
        document.cookie = `${cookieName}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
      })
    }
    // End cleanup cookie logic
  },
})
